import { Breadcrumb, Button, Col, Form, Image, Input, Modal, Rate, Row, Skeleton, Upload, UploadFile, UploadProps, message } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InboxOutlined } from '@ant-design/icons'
import { BsFillCartPlusFill, BsShieldCheck } from 'react-icons/bs'
import { BiCertification } from 'react-icons/bi'
import { AiFillGift } from 'react-icons/ai'
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { firestore, storage } from "../../firebase.config";
import dayjs from "dayjs";
import { checkDocumentExists, saveComment, updateReviewItem } from "service/firebase";
import LoadingSpinner from "components/lds-spinner/lds-spinner";
import { useDispatch, useSelector } from "react-redux";
import { TypeStore, dispatchCart } from "store/reducers";
import Slider from "react-slick";
import { addDoc, collection, doc, getDoc, getDocs, limit, query, where } from "firebase/firestore";
import './style.css'
import { CommentType, ItemType } from "modal/index";
import { NextClick, PrevClick, addToCart, listIconsStore } from "utils"
import { v4 as uuidv4 } from 'uuid';
import { DanhGia } from "./danh-gia";
import { BinhLuan } from "./binh-luan";
import { CamKet } from "./cam-ket";
import { SanPhamDaXem } from "./san-pham-da-xem";
import { SanPhamLienQuan } from "./san-pham-lien-quan";

const props: UploadProps = {
    beforeUpload: (file) => {
        const isPNG = ["image/jpeg", "image/png"].includes(file.type);
        if (!isPNG) {
            message.error(`${file.name} không đúng định dạng (pbg/jpg)`);
        }
        return isPNG || Upload.LIST_IGNORE;
    },
    customRequest: ({ onSuccess }: any) => {
        return onSuccess('ok')
    },
};

export function Detail() {

    const [form] = Form.useForm();
    const history = useNavigate()
    const { _id } = useParams();
    const dispatch = useDispatch()
    const [rate, setRate] = useState(0)
    const { themelayout, system } = useSelector((state: TypeStore) => state)
    const firstItemRef = useRef<any>(null);

    const [nav1, setNav1] = useState<Slider | undefined>(undefined);
    const [nav2, setNav2] = useState<Slider | undefined>(undefined);
    const slider1 = useRef<Slider | null>(null);
    const slider2 = useRef<Slider | null>(null);

    const [open, setOpen] = useState(false)

    const [product, setProduct] = useState<ItemType | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [listRate, setListRate] = useState<number[]>([])
    const [count, setCount] = useState(1)
    const [listFiles, setListFiles] = useState<UploadFile[]>([]);
    const [listComments, setListComment] = useState<CommentType[]>([])
    const [isLoadingComment, setIsLoadingComment] = useState(false)
    const [reloadComment, setReloadComment] = useState(0)
    const [reloadProduct, setReloadProduct] = useState(0)
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [selectedAvatar, setSelectedAvatar] = useState('')

    const [phone, setPhone] = useState<any>(null)

    const [reloadCart, setReloadCart] = useState(0)

    useEffect(() => {
        if (product) {
            const dataStorage = sessionStorage.getItem('watched');
            const { id, name, priceSale, price, rate, typeProduct, listFiles } = product || {};
            const filteredFiles = listFiles?.filter((file) => !file.includes('.mp4')).slice(0, 2);
            if (dataStorage) {
                const existSession = JSON.parse(dataStorage);
                if (existSession && Array.isArray(existSession)) {
                    const existValue = existSession.some((items) => items.id === product?.id)
                    if (!existValue) {
                        const newObject = { id, name, priceSale, price, rate, typeProduct, listFiles: filteredFiles };

                        const updatedSession = [newObject, ...existSession];

                        const limitedSession = updatedSession.slice(0, 5);

                        sessionStorage.setItem('watched', JSON.stringify(limitedSession));
                    }
                }
            } else {
                const newObject = { id, name, priceSale, price, rate, typeProduct, listFiles: filteredFiles };
                sessionStorage.setItem('watched', JSON.stringify([newObject]));
            }
        }
    }, [product]);

    console.log('====================================');
    console.log(product);
    console.log('====================================');


    useEffect(() => {
        if (slider1.current) {
            setNav1(slider1.current);
        }
        if (slider2.current) {
            setNav2(slider2.current);
        }
    }, [product]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        nextArrow: <NextClick />,
        prevArrow: <PrevClick />,
        slidesToShow: Math.min(5, product ? product.listFiles.length : 0),
        slidesToScroll: Math.min(5, product ? product.listFiles.length : 0),
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: Math.min(5, product ? product.listFiles.length : 0),
                    slidesToScroll: Math.min(5, product ? product.listFiles.length : 0),
                },
            },
            {
                breakpoint: 430,
                settings: {
                    slidesToShow: Math.min(5, product ? product.listFiles.length : 0),
                    slidesToScroll: Math.min(5, product ? product.listFiles.length : 0),
                },
            },
            {
                breakpoint: 336,
                settings: {
                    slidesToShow: Math.min(2, product ? product.listFiles.length : 0),
                    slidesToScroll: Math.min(2, product ? product.listFiles.length : 0),
                },
            },
            {
                breakpoint: 246,
                settings: {
                    slidesToShow: Math.min(1, product ? product.listFiles.length : 0),
                    slidesToScroll: Math.min(1, product ? product.listFiles.length : 0),
                },
            },
        ]
    };


    const fetchProduct = async () => {
        try {
            setIsLoading(true)
            if (_id) {
                const itemRef = doc(firestore, "items", _id);
                const data = await getDoc(itemRef)
                const product = { id: data.id, ...data.data() } as any
                setProduct(product)
                setSelectedAvatar(product?.listFiles[0])
                const rageReview = product?.rageRate
                if (rageReview && Array.isArray(rageReview)) {
                    setListRate(rageReview)
                } else {
                    setListRate([])
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const fetchComment = async () => {
        try {
            setIsLoadingComment(true)
            if (_id) {
                const items = await getDocs(query(collection(firestore, "comments"), where("idProduct", "==", _id), limit(5)))
                const comments = items.docs.map((doc) => {
                    return { id: doc.id, ...doc.data() } as any
                })
                const sortComment = comments.sort((a, b) => (dayjs(a.createdDay).isAfter(dayjs(b.createdDay)) ? -1 : 1))
                setListComment(sortComment)
            }
            setIsLoadingComment(false)
        } catch (error) {
            setIsLoadingComment(false)
        }
    }

    useEffect(() => {
        fetchProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, reloadProduct])

    useEffect(() => {
        fetchComment()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, reloadComment])

    const uploadFile = async () => {
        try {
            const fileUploadPromises = listFiles.map((file) => {
                return new Promise<string>(async (resolve, reject) => {
                    if (file.originFileObj) {
                        const reader = new FileReader();
                        reader.onload = async (event) => {
                            if (event.target && event.target.result) {
                                const fileData = event.target.result as ArrayBuffer;
                                const storageRef = ref(storage, `commentImages/${file.name}`);
                                const uploadTask = uploadBytesResumable(storageRef, fileData);

                                uploadTask.on('state_changed', null, reject, () => {
                                    getDownloadURL(storageRef)
                                        .then((downloadURL) => {
                                            resolve(downloadURL);
                                        })
                                        .catch(reject);
                                });
                            } else {
                                reject('Failed to read file data');
                            }
                        };
                        reader.readAsArrayBuffer(file.originFileObj);
                    } else {
                        reject('No originFileObj found');
                    }
                });
            });

            const downloadURLs = await Promise.all(fileUploadPromises);
            message.success('Tải lên thành công');
            return downloadURLs
        } catch (error) {
            message.error('Đã xảy ra lỗi khi tải lên');
            return []
        }
    }

    const onFinish = async (values: any) => {
        try {
            setIsLoading(true)
            const listFiles = await uploadFile()
            const existOrder = await checkDocumentExists('order', 'email', values.authorEmail)
            const data = values
            data.rate = rate
            data.listFiles = listFiles
            data.createdDay = String(dayjs())
            data.reply = null
            data.purchased = existOrder
            data.idProduct = _id
            delete data.images

            const review = product ? product.reviews + 1 : 1
            const rageArray = listRate
           
            rageArray.push(rate)

            const num = rageArray.reduce((num: number, cur: number) => num += cur, 0)
            const average = Math.ceil(num / listRate.length)
            console.log('====================================');
            console.log('rageArray', rageArray);
            console.log('====================================');
            await saveComment(data)
            if (_id) {
                await updateReviewItem(_id, review, rageArray, average)
            }
            setListComment((prev) => [...prev, data].sort((a, b) => (dayjs(a.createdDay).isAfter(dayjs(b.createdDay)) ? -1 : 1)))
            setReloadComment(prev => prev + 1)
            setReloadProduct(prev => prev + 1)
            setIsLoading(false)
            setOpen(false)
            message.success('Tạo bình luận mới thành công');
        } catch (error) {
            message.error('Tạo bình luận mới thất bại');
        } finally {
            setIsLoading(false)
        }
    }

    const onAdd = (item: ItemType) => {
        addToCart(item, count)
        setReloadCart(prev => prev + 1)
    };

    const handelBuy = (item: ItemType) => {
        const existValue = sessionStorage.getItem('cart')
        if (existValue) {
            const arrayValue: ItemType[] = JSON.parse(existValue)
            if (arrayValue && Array.isArray(arrayValue)) {
                const exist = arrayValue.find((x) => x.id === item.id)
                if (exist) {
                    dispatch(dispatchCart(arrayValue))
                    history('/dat-hang')
                } else {
                    const newValue = [...arrayValue, { ...item, qty: count }]
                    sessionStorage.setItem('cart', JSON.stringify(newValue))
                    dispatch(dispatchCart(newValue))
                    history('/dat-hang')
                }
            }
        } else {
            const newValue = [{ ...item, qty: count }]
            sessionStorage.setItem('cart', JSON.stringify(newValue))
            dispatch(dispatchCart(newValue))
            history('/dat-hang')
        }
        setReloadCart(prev => prev + 1)
    }

    useEffect(() => {
        const existValue = sessionStorage.getItem('cart')
        if (existValue) {
            const arrayValue: any = JSON.parse(existValue)
            dispatch(dispatchCart(arrayValue))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadCart])
    //asd

    const starNum = useMemo(() => {
        if (listRate.length === 0) {
            return {
                num: 0,
                string: '0'
            }
        } else {
            const num = listRate.reduce((num: number, cur: number) => num += cur, 0)
            const average = num / listRate.length
            return {
                num: Math.ceil(average),
                string: average.toFixed(1)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRate, reloadComment])

    useEffect(() => {
        if (open === false) {
            form.resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const addMessageToFirebase = async (text: any) => {
        try {
            setIsLoading(true)
            const messagesRef = collection(firestore, 'order-now');
            await addDoc(messagesRef, {
                id: uuidv4(),
                timestamp: Date.now(),
                sdt: text,
                createdDate: dayjs().format('DD/MM/YYYY'),
                product: {
                    name: product ? product.name : 'product',
                    type: product ? product.type : 'type'
                }
            });
            message.success('Gửi yêu cầu thành công')
        } catch (error) {
            console.error('Lỗi khi gửi tin nhắn:', error);
            message.error('Gửi yêu cầu thất bại')
        } finally {
            setIsLoading(false)
        }
    };

    const goiLai = async () => {
        if (phone) {
            await addMessageToFirebase(phone);
        }
    }

    useEffect(() => {
        const targetElement = document.getElementById('scroll-detail');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [_id]);

    const openFile = (url: string) => {
        window.open(url, '_blank')
    }

    console.log(product)

    return <div className="main">
        <div id="scroll-detail">
            {isLoading && <LoadingSpinner />}
            <Modal open={selectedImage != null} onCancel={() => setSelectedImage(null)} onOk={() => setSelectedImage(null)} footer={false}>
                <div className="frame-img-modal">
                    {selectedImage && <img src={selectedImage} alt="" />}
                </div>
            </Modal>
          
            {product
                && <div className="default-container" ref={firstItemRef} >
                    <div className="flex-col gap-12">
                    <Breadcrumb
                        items={[
                        {
                            title: 'Trang chủ',
                        },
                        {
                            title: <a href={`/danh-muc/${product.type}`}>{product.type}</a>,
                        },
                        {
                            title: <a href={`/danh-muc/${product.type}?sub=${product.subCategory}`}>{product.subCategoryName}</a>,
                        },
                        ]}
                        />
                        <section className="flex-row gap-32 section-detail">
                        
                            <div className="slide-detail flex-col gap-32 item-repon align-center">
                                <div className="detail-image limit relative">
                                    <Slider asNavFor={nav2} ref={slider1}>
                                        {product.listFiles.map((element: string) => {
                                            const isVideo = element.includes('.mp4')
                                            return (
                                                <div className="frame-detail-img">
                                                    {isVideo
                                                        ? <video autoPlay loop muted className="image detail">
                                                            <source src={element} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        : <Image className="image detail" width='100%' height='100%' src={element} alt="" />}
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                                <div className={`frame-slider ${themelayout}`}>
                                    <Slider {...settings}
                                    className={product.listFiles.length < 5 ? 'min-slider detail' : ''}
                                        asNavFor={nav1}
                                        ref={slider2}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        // centerMode={product.listFiles.length > 4 ? false : true}
                                    >
                                        {product.listFiles.map((element: string) => {
                                            const isVideo = element.includes('.mp4')
                                            return (
                                                <div className='flex-col gap-12 flex-row-center flex-col-center pointer slide-item'
                                                    onClick={() => setSelectedAvatar(element)} key={element}>
                                                    {isVideo
                                                        ?
                                                        <video className='splider-img detail' >
                                                            <source src={element} type="video/mp4" style={{
                                                                opacity: element === selectedAvatar ? 1 : 0.5
                                                            }} />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        : <img className='splider-img detail' alt='Trầm hương' src={element} style={{
                                                            opacity: element === selectedAvatar ? 1 : 0.5
                                                        }} />
                                                    }
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                                {/* <div className="grid-col-1 gap-32 pd-12">
                                    <div className="shield flex-row flex-center gap-8" style={{
                                        minWidth: 'fit-content'
                                    }}>
                                        <BiCertification className="text-18 status-text" />
                                        <span className="text-13 f-500">Chứng nhận</span>
                                    </div>
                                    <div className="shield flex-row flex-center gap-8" style={{
                                        minWidth: 'fit-content'
                                    }}>
                                        <BsShieldCheck className="text-18 status-text" />
                                        <span className="text-13 f-500">Chính sách bảo hành</span>
                                    </div>
                                </div> */}
                            </div>
                            <div className="flex-col gap-32 item-repon pd-12">
                                <h1 className="f-600">{product.name}</h1>
                                <div className="flex-row gap-8 col-center">
                                    {/* <span className="text-15 f-400">{product.rate}</span> */}
                                    <Rate disabled value={starNum.num} className={`${themelayout}`} />
                                    <div className="line-row">
                                    </div>
                                    <span className="text-15">{listRate.length} <span className="text-blur">đánh giá</span></span>
                                    <div className="line-row">
                                    </div>
                                    <span className="text-15">{product.purchases} <span className="text-blur">đã bán</span></span>
                                </div>
                                <p className="text-15">Tình trạng: <span className="f-600 status-text">{product.status}</span></p>
                                <div className="flex-row gap-8">
                                    <h1 className="f-600 label-color">{Number(product.priceSale).toLocaleString()} VNĐ</h1>
                                    {product.price &&
                                        <div className="flex-row flex-center relative">
                                            <span className="text-15 f-500 text-blur">{Number(product.price).toLocaleString()} VNĐ</span>
                                            <div className="line-center"></div>
                                        </div>
                                    }
                                </div>
                                <div className="flex-row gap-8 flex-between">
                                    <span className="text-15">Số lượng</span>
                                    <div className="count flex-row gap-8 flex-between">
                                        <div className="action-count flex-row flex-center text-15 left" onClick={() => setCount((prev) => Math.max(prev - 1, 1))}>
                                            -
                                        </div>
                                        <div className="count-item flex-row flex-center">
                                            {count}
                                        </div>
                                        <div className="action-count flex-row flex-center text-15 right" onClick={() => setCount((prev) => prev + 1)}>
                                            +
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-col gap-12 flex-center">
                                    <div className="grid-col-1 gap-32 pd-12 button-buy">
                                        <div className="buy-action flex-row flex-center buy pointer" onClick={() => handelBuy(product)}>
                                            <h1 className="text-15 f-600">Mua ngay</h1>
                                        </div>
                                        <div className="buy-action flex-row flex-center pointer add-to-cart gap-8" onClick={() => {
                                            onAdd(product)
                                        }}>
                                            <BsFillCartPlusFill className="text-16" />
                                            <h1 className="text-15 f-600">Thêm vào giỏ hàng</h1>
                                        </div>
                                    </div>
                                    {/* <p className="text-14">Gọi đặt mua {system.sdt} (8h-22h)</p> */}
                                    <div className="flex-row gap-12 flex-between w-100">
                                        <Form className="flex-row w-100">
                                            <Form.Item name={'goilai'} className="flex-row w-100 mgb-0 gap-12" rules={[
                                                { pattern: /^(\+84|0)\d{9,10}$/, message: 'Số điện thoại không hợp lệ' }
                                            ]}>
                                                <Input onChange={(event) => setPhone(event.target.value)} name="goilai" style={{
                                                    background: 'white'
                                                }} className="goilai mgb-0 w-100" placeholder="Nhập SĐT để yêu cầu gọi lại" />
                                            </Form.Item>
                                            <Button type="dashed" onClick={goiLai} style={{
                                                height: '40px'
                                            }}>
                                                Gửi
                                            </Button>
                                        </Form>
                                    </div>
                                    <h1 className="text-13 text-blur f-600">Hoặc mua trên</h1>
                                    <div className="flex-row gap-12 flex-center flex-wrap">
                                        {listIconsStore.map((element) => {
                                            const zalo = system.zalo
                                            const data: any = {...product, zalo}
                                            const url = data[element.alt] ?? ''
                                            return (
                                                <div onClick={() => openFile(url)} className="flex-row box-shadow bdr-4 flex-center pd-4 bd-w pointer hover-scale"
                                                    style={{
                                                        width: '50px',
                                                        height: '50px'
                                                    }}
                                                >
                                                    <img src={element.src} alt={element.alt} style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain'
                                                    }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {product.promotion
                                    && <div className="flex-col w-100 bdr-4" style={{
                                        border: '1px solid #ED7103'
                                    }}>
                                        <div className="pd-12" style={{
                                            background: 'rgb(250 181 90 / 20%)'
                                        }}>
                                            <div className="flex-row gap-4 flex-row-center" style={{
                                                color: '#ED7103'
                                            }} >
                                                <AiFillGift className="text-18" />
                                                <h1 className="text-18 f-600">Khuyến mãi</h1>
                                            </div>
                                        </div>
                                        <div className="flex-col gap-4">
                                            <p className="text-13 pdl-12 pdr-12 pdt-12 f-600">Ưu đãi độc quyền khi mua Online tại Web</p>
                                            <p className="pdl-12 pdr-12 pdb-12">{product.promotion?.split('\n').map((paragraph: string, index: number) => (
                                                <p className="text-13 f-400" key={index}>{paragraph}</p>
                                            ))}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>
                        <section className="flex-col gap-32">
                            <CamKet />
                            {(product.productInf && Array.isArray(product.productInf)
                                && product.productInf.length > 0)
                                && <div className="flex-col infomation">
                                    <div className={`info-item col-1 ${themelayout}`}>
                                        <h1 className="text-15 f-600">THÔNG TIN SẢN PHẨM</h1>
                                    </div>
                                    {product.productInf.map((element: any, index: number) => (
                                        <div className={`info-item flex-row gap-8 col-${index % 2 === 0 ? 2 : 1} ${themelayout}`}>
                                            <span className="text-13 f-400 min-fit">{element.title}</span>
                                            <span className="text-13 f-400">:</span>
                                            <span className="text-13 f-400">{element.sub}</span>
                                        </div>
                                    ))}
                                </div>}
                        </section>
                        {(product.listdsc && Array.isArray(product.listdsc) && product.listdsc.length > 0)
                            && <section className="flex-col gap-12">
                                <div className="header-inf">
                                    <h1 className="text-15 f-600">MÔ TẢ SẢN PHẨM</h1>
                                </div>
                                <div className="box-inf flex-col gap-4">
                                    {product.listdsc.map((item: any) => {
                                        const paragraphs = item.sub.split('\n').map((paragraph: string, index: number) => (
                                            <p className="text-13 f-400" key={index}>{paragraph}</p>
                                        ));
                                        return (
                                            <div className="flex-col gap-8">
                                                <h1 className="text-15 f-600">{item.title}</h1>
                                                <div className="flex-col gap-4">
                                                    {paragraphs}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        }
                        <section className="flex-col gap-12 pd-12">
                            <h1 className="text-15 f-600">ĐÁNH GIÁ SẢN PHẨM</h1>
                            <DanhGia productName={product.name} listRate={listRate} setOpen={setOpen} starNum={starNum} />
                            {isLoadingComment
                                ? <Skeleton avatar paragraph={{ rows: 4 }} active />
                                : <BinhLuan listComments={listComments} setSelectedImage={setSelectedImage} themelayout={themelayout} />
                            }
                            <Modal footer={null} title={product.name} centered open={open} onCancel={() => setOpen(false)}>
                                <Form form={form} layout="vertical" onFinish={(values) => onFinish(values)} className="form-design">
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <Form.Item name={'sub'} label="Nội dung" rules={[
                                                { required: true, message: 'Vui lòng nhập đánh giá' }
                                            ]}>
                                                <TextArea />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={'images'} label="Hình ảnh">
                                                <Dragger multiple {...props} onChange={(values) => setListFiles(values.fileList)}>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text text-14">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
                                                </Dragger>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <p className="text-13 f-600">Bạn cảm thấy thế nào về sản phẩm? (Chọn sao)</p>
                                        </Col>
                                        <Col span={24}>
                                            <Rate onChange={(value) => setRate(value)} />
                                        </Col>
                                        <Col span={24}>
                                            <Row gutter={[12, 12]}>
                                                <Col span={24}>
                                                    <Form.Item name={'authorName'} label="Họ và tên" rules={[
                                                        { required: true, message: 'Vui lòng nhập đánh giá' }
                                                    ]}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name={'authorSdt'} label="Số điện thoại" rules={[
                                                        { required: true, message: 'Vui lòng nhập số điện thoại' },
                                                        { pattern: /^(\+84|0)\d{9,10}$/, message: 'Số điện thoại không hợp lệ' }
                                                    ]}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name={'authorEmail'} label="Email" rules={[
                                                        { required: true, message: 'Vui lòng nhập email' },
                                                        {
                                                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: 'Email không hợp lệ'
                                                        }
                                                    ]}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Button type="primary" htmlType="submit">
                                                        Bình luận
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal>
                        </section>
                        <SanPhamDaXem />
                        <SanPhamLienQuan categories={product?.type ?? ''} />
                    </div>
                </div>}
        </div>
    </div>
}